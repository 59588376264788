import axios, { AxiosInstance } from "axios";

export default class LambdaGatewayApiService {
  private static httpClient: AxiosInstance = axios.create({
    baseURL: "https://a18praysb5.execute-api.eu-north-1.amazonaws.com",
    validateStatus: status => (status >= 200 && status < 300) || status === 500
  });

  public static async FinalizeSupplyAsync(seller: number, apiKey: string): Promise<string> {
    let url = "";
    switch(seller) { 
      case 0: { 
        url = "/default/MSSupplyFinalizer";
        break; 
      } 
      case 1: { 
        url = "/default/Aleshkov_MSSupplyFinalizer";
        break; 
      }
      case 2: { 
        url = "/default/TESTAPIGATEWAY";
        break; 
      } 
    } 

    try {
      const response = await this.httpClient.post(url, null, { headers: { "x-api-key": apiKey }});
      if (response) {
        if (response.status === 500) 
        {
          throw new Error(response.data);
        }

        return response.data;
      }
    }
    catch (error: any) {
      throw error.message;
    }

    throw "Неизвестная ошибка.";
  }
}