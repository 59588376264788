import { useState } from "react";

const WildberriesIcon = () => {
    return (<svg width="20.56px" height="20.56px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 12C0 5.37258 5.37258 0 12 0H36C42.6274 0 48 5.37258 48 12V36C48 42.6274 42.6274 48 36 48H12C5.37258 48 0 42.6274 0 36V12Z" fill="url(#paint0_linear_11_61)"/>
    <path d="M13.85 32.5H10.6958L6 16H8.86965L12.3559 28.8936L16.1268 16H18.6407L22.3878 28.8936L25.8741 16H28.7437L24.0479 32.5H20.8937L17.3837 20.455L13.85 32.5ZM39.6758 23.9436C41.1225 24.6979 42 26.065 42 27.7857C42 29.1293 41.5257 30.2607 40.5533 31.1564C39.581 32.0521 38.4189 32.5 37.0196 32.5H29.9048V16H36.4979C37.8497 16 39.0118 16.4479 39.9367 17.32C40.8853 18.1921 41.3597 19.2764 41.3597 20.5729C41.3597 22.0107 40.7905 23.1186 39.6758 23.9436ZM36.4979 18.5457H32.6321V22.8829H36.4979C37.7074 22.8829 38.6323 21.94 38.6323 20.7143C38.6323 19.4886 37.7074 18.5457 36.4979 18.5457ZM32.6321 29.9543H37.0196C38.2766 29.9543 39.2727 28.9407 39.2727 27.6443C39.2727 26.3479 38.2766 25.3343 37.0196 25.3343H32.6321V29.9543Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_11_61" x1="-0.101122" y1="2.88128" x2="52.4352" y2="9.57311" gradientUnits="userSpaceOnUse">
    <stop stopColor="#ED3CCA"/>
    <stop offset="0.145833" stopColor="#DF34D2"/>
    <stop offset="0.291667" stopColor="#D02BD9"/>
    <stop offset="0.432292" stopColor="#BF22E1"/>
    <stop offset="0.572917" stopColor="#AE1AE8"/>
    <stop offset="0.713542" stopColor="#9A10F0"/>
    <stop offset="0.854167" stopColor="#8306F7"/>
    <stop offset="1" stopColor="#7C1AF8"/>
    </linearGradient>
    </defs>
    </svg>);
};

export default WildberriesIcon;
