import { Box, FormControlLabel, LinearProgress, Typography, Link } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Switch } from  "../../components/Switch";
import { ApiKeyTextField } from "./ApiKeyTextField";
import EventBridgeRulesService from "../../services/EventBridgeRulesService";
import SystemManagerParametersService from "../../services/SystemManagerParametersService";

interface AdminProps {
    title: string;
}

export const Admin = ({ title }: AdminProps)=> {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => { document.title = title } , []);

    
    const [initialSetupFinished, setInitialSetupFinished] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiKey, setApiKey] = useState("");
    
    const [errorResult, setErrorResult] = useState<{ isError: boolean, errorMessage: string }>({ isError: false, errorMessage: "" });

    const [newOrdersState, setNewOrdersState] = useState<{initial: boolean, state: boolean}>({initial: true, state: false});
    const [quantitySynchronizerState, setQuantitySynchronizerState] = useState<{initial: boolean, state: boolean}>({initial: true, state: false});

    const [newOzonOrdersState, setOzonNewOrdersState] = useState<{initial: boolean, state: boolean}>({initial: true, state: false});
    
    const [lastCheckedNewOrderDate, setLastCheckedNewOrderDate] = useState("");
    const [currentMSMoveId, setCurrentMSMoveId] = useState("");
    const [currentWBSupplyId, setCurrentWBSupplyId] = useState("");
    
    useEffect(() => { 
        if (apiKey === "") {
            setLoading(false)
            setInitialSetupFinished(false)
            setErrorResult({isError: false, errorMessage: ""})
        } 
        else {
            setLoading(true);

            Promise.all([
                EventBridgeRulesService.GetListRulesAsync(apiKey), 
                SystemManagerParametersService.GetParameterValueAsync(apiKey, "lastcheckeddate"),
                SystemManagerParametersService.GetParameterValueAsync(apiKey, "currentmsmoveid"),
                SystemManagerParametersService.GetParameterValueAsync(apiKey, "currentwbsupplyid")],
            )
                .then(function(results) {
                    setErrorResult({isError: false, errorMessage: ""})

                    setNewOrdersState({initial: true, state: results[0].find(x => x.name === 'RunEvery1MinuteNewOrders')?.enabled!});
                    setQuantitySynchronizerState({initial: true, state: results[0].find(x => x.name === 'RunEvery1MinuteMSToWBSynchronizer')?.enabled!});

                    setOzonNewOrdersState({initial: true, state: results[0].find(x => x.name === 'RunEvery5MinuteOzonNewOrders')?.enabled!});
                    
                    setLastCheckedNewOrderDate(results[1]);
                    setCurrentMSMoveId(results[2]);
                    setCurrentWBSupplyId(results[3]);

                    setInitialSetupFinished(true);
                })
                .catch(e => {
                    setErrorResult({isError: true, errorMessage: "Невереный ключ"})
                })
                .finally(() => { setLoading(false) });
        }
    } , [apiKey]);

    useEffect(() => { 
        if (newOrdersState.initial)
            return;

        changeRule('RunEvery1MinuteNewOrders', newOrdersState.state!)
    } , [newOrdersState]);

    useEffect(() => { 
        if (quantitySynchronizerState.initial)
        return;

        changeRule('RunEvery1MinuteMSToWBSynchronizer', quantitySynchronizerState.state)
    } , [quantitySynchronizerState]);

    useEffect(() => { 
        if (newOzonOrdersState.initial)
        return;

        changeRule('RunEvery5MinuteOzonNewOrders', newOzonOrdersState.state)
    } , [newOzonOrdersState]);

    function changeRule(name: string, state: boolean)
    {
        if (state)
        {
            setLoading(true);
            EventBridgeRulesService.EnableRuleAsync(apiKey, name)
                .finally(() => { setLoading(false) });
        } else
        {
            setLoading(true);
            EventBridgeRulesService.DisableRuleAsync(apiKey, name)
                .finally(() => { setLoading(false) });
        }
    }

    return (
        <Box m="20px">
            <Header title="Управление автоматическим запуском функций" subtitle="Включение и отключение бэкграунд процессов" />
            
            <Box
                display="grid"
                mb="5px"
            >
                <ApiKeyTextField disabled={loading} setApiKey={setApiKey} />
            </Box>
            <Box
                display="grid"
                mb="15px"
            >
                 {loading && (<LinearProgress  sx={{ backgroundColor: colors.greenAccent[800],}}/>)}
            </Box>

            { errorResult.isError && 
                <Box display="grid">
                    <Typography fontSize={18} color={colors.redAccent[500]} variant="h5">
                        {errorResult.errorMessage}
                    </Typography>
                </Box> 
            }

            {initialSetupFinished &&
                (<>
                    <Box
                        display="grid"
                        mb="20px"
                    >
                        <FormControlLabel control={
                            <Switch name="RunEvery1MinuteNewOrders" disabled={loading} checked={newOrdersState.state} setChecked={setNewOrdersState} />
                        }
                        label={<Typography variant="h5" color={colors.grey[100]}>WB - Авто обработка новых заказов</Typography>}
                        />
                    </Box>
                    <Box
                        display="grid"
                        mb="20px"
                    >
                        <FormControlLabel control={
                            <Switch name="RunEvery1MinuteMSToWBSynchronizer" disabled={loading} checked={quantitySynchronizerState.state} setChecked={setQuantitySynchronizerState} />
                        }
                        label={<Typography variant="h5" color={colors.grey[100]}>Авто синхронизация остатков MS в WB</Typography>}
                        />
                    </Box>
                    <Box
                        display="grid"
                        mb="20px"
                    >
                        <FormControlLabel control={
                            <Switch name="RunEvery5MinuteOzonNewOrders" disabled={loading} checked={newOzonOrdersState.state} setChecked={setOzonNewOrdersState} />
                        }
                        label={<Typography variant="h5" color={colors.grey[100]}>OZON - Авто обработка новых заказов</Typography>}
                        />
                    </Box>


                    <Box
                        display="grid"
                        mb="20px"
                    >
                        <Typography variant="h5" color={colors.grey[100]}>Время последней проверки новых заказов: {new Date(lastCheckedNewOrderDate).toLocaleString()}</Typography>
                    </Box>
                    <Box
                        display="grid"
                        mb="20px"
                    >
                        <Typography variant="h5" color={colors.grey[100]}>Идентификатор текущего перемещения MS: <Link  sx={{ color: colors.blueAccent[500] }} href={`https://online.moysklad.ru/app/#move/edit?id=${currentMSMoveId}`}>{currentMSMoveId}</Link></Typography>
                    </Box>
                    <Box
                        display="grid"
                        mb="20px"
                    >
                        <Typography variant="h5" color={colors.grey[100]}>Идентификатор текущей постаки WB: <Link  sx={{ color: colors.blueAccent[500] }} href={`https://seller.wildberries.ru/marketplace-orders-new/on-assembly/to-warehouse/supply-detail/orders?supplyID=${currentWBSupplyId}`}>{currentWBSupplyId}</Link></Typography>
                    </Box>
                </>)
            }
        </Box>
    );
};

