import { Switch as MuiSwitch } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useEffect, useState } from "react";

interface SwitchProps {
    name: string;
    disabled: boolean;
    checked: boolean;
    setChecked: React.Dispatch<React.SetStateAction<{
        initial: boolean;
        state: boolean;
    }>>;
}

export const Switch = ({name, disabled, checked, setChecked}: SwitchProps) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <MuiSwitch 
            name={name}
            disabled={disabled}
            checked={checked}
            onClick={() => setChecked({initial: false, state: !checked})}
            sx={{
                '& .MuiSwitch-switchBase': {
                    '&.Mui-checked': {
                        color: colors.primary[100],
                        '& + .MuiSwitch-track': {
                            backgroundColor: colors.greenAccent[400],
                            opacity: 1,
                            border: 0,
                        },
                    },
                },
            }}
        />
    )}
;