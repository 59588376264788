import { createContext, useContext } from "react";

interface SidebarContextProps {
    toggled: boolean;
    setToggled: React.Dispatch<React.SetStateAction<boolean>>;
    broken: boolean;
    setBroken: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SidebarContext = createContext<SidebarContextProps>({ toggled: false,  setToggled: () => {}, broken: false,  setBroken: () => {} });

export function useSidebarContext()
{
    const sidebarInfo = useContext(SidebarContext);

    return sidebarInfo;
}