import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { SidebarContext as ProSidebarContext } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebar/sidebarContext"

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { toggled } = useContext(ProSidebarContext);
  const { setToggled, broken } = useSidebarContext();

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
       <Box display="flex">
        {broken && (
            <IconButton
              sx={{ mr: 2 }}
              onClick={() => {
                console.log(toggled);
                setToggled(!toggled)}
              }
            >
              <MenuOutlinedIcon />
            </IconButton>
        )}
        {/* SEARCH BAR */}
        <Box
          sx={{
            display: "flex",
            backgroundColor: colors.primary[400],
            borderRadius: "3px",
          }}
        >
          <InputBase sx={{ ml: 2, fl: 1 }} placeholder="Search" />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>
      {/* ICONS */}
      <Box sx={{ display: "flex" }}>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
