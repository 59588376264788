import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/sidebar/Sidebar";
import { Dashboard } from "./scenes/dashboard";
import { SendToDelivery } from "./scenes/sendToDelivery";
import { ConvertExcelToMoySklad } from "./scenes/convertExcelToMoySklad";
import { SidebarContext } from "./scenes/global/sidebar/sidebarContext";
import { Admin } from "./scenes/admin/intex";

export const App = () => {
  const [theme, colorMode] = useMode();

  const [broken, setBroken] = useState(false);
  const [toggled, setToggled] = useState(false);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <div className="app">
          <SidebarContext.Provider value={{ toggled, setToggled, broken, setBroken }}>
            <Sidebar></Sidebar>
            <main className="content">
              <Topbar />
              <Routes>
                <Route path="/" element={<Dashboard title="Дашборд" />} />
                <Route path="/sendToDelivery" element={<SendToDelivery title="Отправить WB поставку на доставку"  />} />
                <Route path="/convertExcelToMoySklad" element={<ConvertExcelToMoySklad title="Конвертируем Excel"  />} />
                <Route path="/admin" element={<Admin title="Админка"  />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </main>
            </SidebarContext.Provider>
          </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
