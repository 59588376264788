import { Box, IconButton, LinearProgress, TextField } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface ApiKeyTextFieldProps {
    disabled: boolean;
    setApiKey: React.Dispatch<React.SetStateAction<string>>;
}

export const ApiKeyTextField = ({ disabled, setApiKey }: ApiKeyTextFieldProps)=> {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [currentApiKey, setCurrentApiKey] = useState("");
    const [isApiKeyProvided, setIsApiKeyProvided] = useState(false);

    const handleApplyClick = () => {
        if (currentApiKey.trim() === "")
            return;

        if (isApiKeyProvided) {

            setApiKey("");
            setCurrentApiKey("");

            setIsApiKeyProvided(false);
        } else {
            setApiKey(currentApiKey);

            setIsApiKeyProvided(true);
        }
    };

    const SearchButton = () => (
        <IconButton onClick={handleApplyClick}>
            {isApiKeyProvided ? <CancelOutlinedIcon /> : <CheckCircleOutlinedIcon />}
        </IconButton>
    )

    return (
        <Box
            display="grid"
            mb="20px"
        >
            <TextField
                variant="filled"
                type="password"
                autoComplete="new-password"
                label="Ключ"
                value={currentApiKey}
                onChange={e => setCurrentApiKey(e.target.value)}
                name="apikey"
                sx={{ gridColumn: "span 4" }}
                disabled={isApiKeyProvided}
                InputProps={{endAdornment: <SearchButton />}}
            />
        </Box>
    );
};

