import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

interface ResultProps {
  isSuccess: boolean;
  resultHtml: string;
}

const Result = ({ isSuccess, resultHtml }: ResultProps) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Typography fontSize={18} color={ isSuccess === true ? colors.greenAccent[500] : colors.redAccent[500] } variant="h5">
            <span dangerouslySetInnerHTML={{ __html: resultHtml }}></span>
        </Typography>
    );
};

export default Result;
